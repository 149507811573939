import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "brain_support" }, [
      _createElementVNode("path", {
        id: "Vector",
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M38.0406 21.9679V27.45H35.1406V21.9693L28.1094 17.0192V8.44922L35.1406 13.7227V7H38.0406V13.7211L45.0698 8.44922V17.0192L38.0406 21.9679Z",
        fill: "#F06F02"
      }),
      _createElementVNode("path", {
        id: "Vector_2",
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M45.4298 24.5996C44.9798 24.6996 44.3598 24.8296 43.5798 24.9596C41.8498 25.2396 39.3698 25.5296 36.3998 25.5296C33.4298 25.5296 30.9398 25.2396 29.2198 24.9596C28.6898 24.8759 28.229 24.7836 27.8454 24.7068L27.7598 24.6896V27.4996C28.0698 27.5596 28.3998 27.6196 28.7698 27.6796C30.6198 27.9796 33.2498 28.2896 36.3998 28.2896C39.5498 28.2896 42.1798 27.9896 44.0298 27.6796C44.5698 27.5896 45.0398 27.4996 45.4398 27.4196V24.6096L45.4298 24.5996ZM2.5 25H11.5V42H4L2.5 25ZM13.0104 27.8043V27.7954L13 27.8057L13.0104 27.8043ZM13.0104 27.8043V40.0252L21.6494 42.5683C22.6221 42.8546 23.6307 43 24.6446 43C25.767 43 26.8823 42.8218 27.9488 42.4722L45.9989 36.5546C46.8826 36.2653 47.2673 35.2531 46.7994 34.4577C46.5083 33.9516 45.9365 33.6727 45.3439 33.745L32.8887 35.8005L23.4486 34.6023V33.5901L31.8682 33.1779C32.6578 33.1393 33.2287 32.4081 33.0747 31.6327C32.9744 31.1277 32.5843 30.7301 32.0813 30.6202L21.6604 28.3428C19.8919 27.4676 17.902 27.1416 15.9467 27.4065L13.0104 27.8043ZM7 31C8.10457 31 9 30.1046 9 29C9 27.8954 8.10457 27 7 27C5.89543 27 5 27.8954 5 29C5 30.1046 5.89543 31 7 31Z",
        fill: "#212529"
      })
    ], -1)
  ])))
}
export default { render: render }